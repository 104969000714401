'use client'
import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'

import { isMobileDevice } from 'helpers/utilities'

// import Step from '../Step'
import SafeInfo from '../Common/SafeInfo'
import { jumpPage } from '../utils'
import { trackAdjustEventBySite } from 'app/components/adjust'
import Image from 'next/image'
import ResumeSuccessIcon from '../../images/success-pc.png'
import { Button } from 'app/(new-pages)/components/Button/pc/index.pc'
import { getCookie } from 'helpers/cookies'
import { getStartedRecord } from 'store/services/eventTrack/record'

const CompleteProfile = (props: { lang: any }) => {
  const { lang } = props

  const profile = lang.profile || {}
  const [countdown, setCountdown] = useState(3)

  useEffect(() => {
    const eventTokens = {
      sg: 'e99ksj',
      jp: 'vl6893',
      ph: 'ttsuqn',
      com: '18cm2i'
    }
    trackAdjustEventBySite(eventTokens)
    const loginBtnClick = getCookie('loginBtnClick')
    if (loginBtnClick === 'Click_Get_Started') {
      handleStartedRecord({ source: 'Sign_In_Successed' })
    }
    if (loginBtnClick === 'Click_Find') {
      handleStartedRecord({ source: 'Sign_In_Successed_Find' })
    }
  }, [])

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prev) => (prev > 0 ? prev - 1 : 0))
    }, 1000)

    if (countdown === 0) {
      jumpPage && jumpPage()
      clearInterval(timer)
    }

    return () => clearInterval(timer)
  }, [countdown])

  const handleStartedRecord = (params = {}) => {
    getStartedRecord(params)
  }

  const handleBack = () => {
    jumpPage && jumpPage()
  }

  return (
    <CompleteProfileWrap>
      <div className='profileWrap'>
        <h1>{profile.completeProfile?.title} 🎉</h1>
        <p>{profile.completeProfile?.subtitle}</p>
        <div className='successContent'>
          <img src={ResumeSuccessIcon.src} alt='' />
        </div>
        <Button className='backBtn' btnSize='size48' variant='primaryBtn' onClick={handleBack}>
          {profile.completeProfile?.button} ({countdown})
        </Button>
      </div>
      {/* <div className='safeInfoWrap'>
        <SafeInfo lang={lang} />
      </div> */}
    </CompleteProfileWrap>
  )
}

const CompleteProfileWrap = styled.div`
  display: flex;
  width: 500px;
  margin: 48px auto;
  flex-direction: column;

  .profileWrap {
    display: flex;
    padding: 32px;
    flex-direction: column;
    align-items: center;
    background: #fff;
    border-radius: 16px;
  }

  h1 {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
    color: #000;
  }

  p {
    color: #4b4c4d;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  .successContent {
    /* width: 436px; */
    /* height: 239px; */
    flex-shrink: 0;
    /* border-radius: 23px; */
    /* background: #edf3ff; */
    margin-top: 24px;
    position: relative;
    overflow: hidden;

    img {
      width: 230.5px;
      height: 227px;
      object-fit: cover;
    }
  }

  .backBtn {
    width: 100%;
    cursor: pointer;
    margin-top: 24px;
  }

  .safeInfoWrap {
    margin-top: 20px;
  }
`

export default CompleteProfile
