import React, { createContext, ReactNode, useContext, useState } from 'react'
import axios from 'axios'
import { message } from 'antd'
import configuredAxios from 'helpers/configuredAxios'
import { isChatProcess } from '../utils'
import { addEventTag, isMobileDevice } from 'helpers/utilities'
import { trackAdjustEventBySite } from 'app/components/adjust'

interface UploadResumeContextType {
  step: number
  setStep: React.Dispatch<React.SetStateAction<number>>
  progress: number
  setProgress: React.Dispatch<React.SetStateAction<number>>
  fileData: any
  cancelTokenSource: any
  uploadResumeFile: (file: any) => Promise<any>
  userData: any
  setUserData: (data: any) => void
}

export const UploadResumeContext = createContext<UploadResumeContextType | undefined>(undefined)

const Provider = UploadResumeContext.Provider

interface UploadResumeProviderProps {
  children: ReactNode
}

export const maxFileSize = 30 * 1000 * 1000

export const UploadResumeProvider: React.FC<UploadResumeProviderProps> = ({ children }) => {
  const [step, setStep] = useState<number>(1)
  const [progress, setProgress] = useState<number>(0)
  const [fileData, setFileData] = useState<any>({})
  const [cancelTokenSource, setCancelTokenSource] = useState(null)
  const [userData, setUserData] = useState<any>({})
  const chatProcess = isChatProcess()
  const isMobile = isMobileDevice()
  const uploadResumeFile = async ({ file, resumeId }) => {
    if (file.size > maxFileSize) {
      message.error('File size exceeds the limit of 30MB')
      return
    }
    setStep(2)
    setProgress(0)

    const source = axios.CancelToken.source()
    setCancelTokenSource(source)
    const formData = new FormData()
    formData.set('file', file)

    const sourceFrom = isMobile ? 'mobile_web' : 'web'
    const chanel = 'resume_register_upload'
    formData.set('source', sourceFrom)
    formData.set('channel', chanel)

    if (resumeId) {
      formData.set('replace_resume_id', resumeId)
    }

    try {
      const axios = configuredAxios('jobseeker')
      const URL = '/resume/upload'
      const response = await axios.post(URL, formData, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          Accept: '*/*',
          'Cache-Control': 'no-cache',
          'Content-Type': 'multipart/form-data'
        },
        cancelToken: source.token,
        onUploadProgress: (progressEvent) => {
          const percent = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          setProgress(percent)

          if (percent === 100) {
            setProgress(99)
          }
        }
      })

      if (response?.status >= 200 && response?.status < 300) {
        const data = response?.data?.data || {}
        setFileData({ ...fileData, data })
        setProgress(100)
        setStep(3)
        const eventTokens = {
          sg: '6tnyu9',
          jp: 'uuqpxe',
          ph: '2lvlwz',
          com: 'oee4vz'
        }
        trackAdjustEventBySite(eventTokens)
        addEventTag(
          chatProcess
            ? 'new_user_chat_resume_upload_success'
            : 'new_user_complete_resume_upload_success'
        )
      } else {
        setStep(4)
        addEventTag(
          chatProcess ? 'new_user_chat_resume_upload_fail' : 'new_user_complete_resume_upload_fail'
        )
      }
    } catch (error) {
      setStep(4)
      addEventTag(
        chatProcess ? 'new_user_chat_resume_upload_fail' : 'new_user_complete_resume_upload_fail'
      )
    }
  }

  return (
    <Provider
      value={{
        userData,
        setUserData,
        fileData,
        cancelTokenSource,
        step,
        setStep,
        uploadResumeFile,
        progress,
        setProgress
      }}
    >
      {children}
    </Provider>
  )
}

export const useUploadResumeContext = (): UploadResumeContextType => {
  const context = useContext(UploadResumeContext)
  if (!context) {
    throw new Error('useUploadResumeContext must be used within a UploadResumeProvider')
  }
  return context
}

export const withUploadResumeProvider = <P extends object>(
  WrappedComponent: React.ComponentType<P>,
  extraProps?: P
) => {
  return function UploadResumeProviderComponent(props: P) {
    return (
      <UploadResumeProvider>
        <WrappedComponent {...props} {...extraProps} />
      </UploadResumeProvider>
    )
  }
}
